import * as React from 'react';

import {
	AspectRatio,
	Box,
	Button,
	HStack,
	Image,
	Link,
	Skeleton,
	Stack,
	Text,
	useBreakpointValue,
} from '@chakra-ui/react';

import AddToCart from '../AddToCart';
import useAuth from '../useAuth';
import { useState } from 'react';

export enum WpStockStatusEnum {
	IN_STOCK = 'IN_STOCK',
	OUT_OF_STOCK = 'OUT_OF_STOCK',
	ON_BACKORDER = 'ON_BACKORDER',
}

export const SingleProductCard = ({
	props,
	qtyInputOn,
	outlet,
	margin,
	width,
	maxW,
	disclaimer,
}: SingleProductCardProps) => {
	const { loggedIn, user } = useAuth();

	const [isOnSale] = useState(props.onSale);

	return (
		<>
			<Stack
				position='relative'
				spacing={useBreakpointValue({ base: '4', md: '5' })}
				minH='400px'
				h={{ base: 'auto', md: '100%' }}
				w={width}
				maxW={{ base: '100%', md: maxW }}
				boxShadow='0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);'
				borderRadius='20px'
				bg='white'
				justifyContent='space-between'
				p='6px'
				margin={margin}>
				{outlet && (
					<Box
						position='absolute'
						top='4px'
						left='4px'
						zIndex='4'
						p='4px 10px'
						borderRadius='40px'
						bg='green.100'
						color='green.800'
						fontSize='14px'
						fontWeight='400'>
						OUTLET
					</Box>
				)}
				{props.promotions?.onPromotion && (
					<Box
						position='absolute'
						zIndex='1'
						top='8px'
						w='fit-content'
						bg='#F0FFF4'
						color='#39A26A'
						textTransform='uppercase'
						borderRadius='8px'
						fontSize='14px'
						px='8px'>
						Low Quantity
					</Box>
				)}
				{props.promotions?.additionalTag && (
					<Box
						position='absolute'
						zIndex='1'
						top='32px'
						w='fit-content'
						bg='#FC8181'
						color='#fff'
						textTransform='uppercase'
						borderRadius='8px'
						fontSize='14px'
						px='8px'>
						{props.promotions?.additionalTag}
					</Box>
				)}

				<Link
					href={`/${props.slug}`}
					_hover={{
						textDecoration: 'none !important',
						color: 'gray.900 !important',
					}}>
					<Box
						position='relative'
						className='image-container'>
						<AspectRatio
							ratio={4 / 3}
							height='224px'
							className='aspect-ratio'>
							<Image
								aria-label={props.name}
								draggable='false'
								fallback={<Skeleton />}
								borderRadius={useBreakpointValue({ base: 'md', md: 'xl' })}
								className='image'
								src={props.image?.sourceUrl}
								alt={props.image?.altText}
								//@ts-ignore
								objectFit={'contain !important'}
							/>
						</AspectRatio>
					</Box>
					<Stack>
						<Stack
							spacing='1'
							pt='20px'
							px='16px'
							fontWeight='bold'
							h='120px'
							justifyContent={'space-between'}>
							<Text
								fontWeight='700'
								fontSize='14px'
								position='relative'
								color='gray.800'
								_hover={{ textDecoration: 'none', color: 'beige.400' }}>
								{props.name}
							</Text>
							<Text
								fontSize='12px'
								fontWeight={400}>
								{disclaimer}
							</Text>
							<HStack>
								{isOnSale ? (
									<>
										<Text
											marginTop='2 !important'
											color='gray.800'
											fontSize='18px'
											fontWeight={600}
											_hover={{ textDecoration: 'none', color: 'gray.800' }}>
											{' '}
											{props.price}{' '}
										</Text>
										<Text
											style={{ textDecoration: 'line-through' }}
											color='red.300'
											fontSize='18px'
											fontWeight={600}
											marginTop='2 !important'
											_hover={{ textDecoration: 'none', color: 'gray.800' }}>
											{props.regularPrice}
										</Text>
									</>
								) : (
									<Text
										color='gray.800'
										fontSize='18px'
										fontWeight={600}
										marginTop='2 !important'
										_hover={{ textDecoration: 'none', color: 'gray.800' }}>
										{props.regularPrice}
									</Text>
								)}
							</HStack>
						</Stack>
					</Stack>
				</Link>

				<Stack align='center'>
					{!loggedIn ? (
						<Link
							href='/sign-in'
							w='100%'>
							<Button
								border='1px solid #120B0C'
								fontWeight={600}
								variant='outlineSm'>
								SIGN IN TO ORDER
							</Button>
						</Link>
					) : (
						<>
							{props.price.includes("-") ? (
								<Link w="100%" href={`/${props.slug}`}>
									<Button
										w="100%"
										border="1px solid #F8F1F0"
										fontWeight={600}
										variant='outline'>CHOOSE</Button>
								</Link>
							) : (
								<AddToCart
									productId={props.databaseId}
									name={props.name}
									price={props.price}
								/>
							)}
						</>
					)}
				</Stack>
			</Stack>
		</>
	);
};

type SingleProductCardProps = {
	qtyInputOn: boolean;
	outlet: boolean;
	margin: any;
	width: any;
	maxW?: any;
	disclaimer?: string;
	props: {
		name: String;
		slug: String;
		price: string;
		regularPrice: string;
		onSale?: boolean;
		databaseId: number;
		sku: number;
		promotions?: {
			onPromotion: boolean;
			additionalTag: string;
		};
		image?: {
			sourceUrl?: string;
			altText?: string;
		};
		productTags: {
			nodes?: [];
		};
	};
};
