import * as React from "react";

import {
  Box,
  Button,
  CloseButton,
  Flex,
  HStack,
  Link,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  VStack,
  useBreakpointValue,
  useNumberInput,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import AddToCart from "../AddToCart";
import { CartProduct } from "../../../types/CartProduct";
import { CartProductMeta } from "./CartProductMeta";
import { GoStack } from "react-icons/go";
import OneProductBundle from "../Product/OneProductBundle";
import ProductBundles from "../Product/ProductBundles";
import useAuth from "../useAuth";

type CartItemType = {
  isGiftWrapping?: boolean;
  name?: string;
  slug: string;
  quantity: number;
  price?: any;
  imageUrl?: string;
  altText?: string;
  productTags: any;
  updateLoading?: boolean;
  databaseId: any;
  key: string;
  gratis?: number;
  onChangeQuantity: (qty: number) => void;
  onClickGiftWrapping?: () => void;
  onClickDelete?: (key: any) => void;
  categories?: CartProduct["productCategories"];
  isSuggested?: boolean;
  bundles?: any;
  productName?: any;
  variationName: string;
  products?: any;
  stockQuantity?: number;

};

export const CartItem: React.FC<CartItemType> = ({
  name,
  slug,
  quantity,
  price,
  imageUrl,
  altText,
  productTags,
  updateLoading,
  databaseId,
  onChangeQuantity,
  onClickDelete,
  gratis,
  isSuggested,
  bundles,
  productName,
  variationName,
  products,
  stockQuantity
}) => {
  const [qtyValue, setQtyValue] = useState(quantity ?? 1);

  useEffect(() => {
    setQtyValue(quantity);
  }, [quantity]);

  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue: quantity ?? 1,
      min: 1,
      max: 999,
      precision: 0,
      value: qtyValue,
      onChange: (str, num) => {
        setQtyValue(num);
        onChangeQuantity(num);
      },
    });

  const formatPrice = (price: number | string): string | null => {
    if (price === "FREE") {
      return "FREE";
    } else if (typeof price === "number") {
      return `$${price.toFixed(2)}`;
    } else if (typeof price === "string") {
      const parsedPrice = parseFloat(price.replace("$", ""));
      if (!isNaN(parsedPrice)) {
        return `$${parsedPrice.toFixed(2)}`;
      }
    }
    return null;
  };


  const { loggedIn } = useAuth();

  return (
    <>
      <Flex
        w="100%"
        direction={{ base: "column", md: "row" }}
        justify="space-between"
        align="center"
        borderBottom="1px solid #D9D9D9"
      >
        <Link
          href={`${slug}`}
          w="full"
          _hover={{ textDecoration: "none", color: "#D28C49" }}
        >
          <CartProductMeta
            // gratis={gratis}
            quantity={quantity}
            name={name}
            variationName={variationName}
            image={imageUrl}
            productTags={productTags}
            altText={altText}
          />
        </Link>

        {/* Desktop */}
        <Flex
          width="full"
          justify={isSuggested ? "space-around" : "space-evenly"}
          alignItems="center"
          display={{ base: "none", md: "flex" }}
          pointerEvents={updateLoading ? "none" : "auto"}
        >
          {!isSuggested && (
            <NumberInput
              min={1}
              max={stockQuantity}
              value={qtyValue}
              onChange={(valueString, valueNumber) => {
                if (!updateLoading) {
                  setQtyValue(valueNumber);
                  onChangeQuantity(valueNumber);
                }
              }}
              focusBorderColor="#d28c49"
              pointerEvents={updateLoading ? "none" : "auto"}
              width="80px"
              isDisabled={updateLoading}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          )}

          {!isSuggested && (
            <CloseButton
              aria-label={`Delete ${name} from cart`}
              onClick={() => onClickDelete && onClickDelete(databaseId)}
              isDisabled={updateLoading}
            />
          )}

          <Text fontWeight="bold" color="#D28C49">
            {formatPrice(price)}
          </Text>

          {isSuggested && (
            <Box w={{ base: "100%", md: "50%" }}>
              {loggedIn ? (
                <AddToCart productId={databaseId} name="" price={price} />
              ) : (
                <Link href="/sign-in" w="100%">
                  <Button
                    border="1px solid #120B0C"
                    fontWeight={600}
                    variant="outlineSm"
                  >
                    SIGN IN TO ORDER
                  </Button>
                </Link>
              )}
            </Box>
          )}
        </Flex>

        {/* Mobile */}
        <Flex
          mt="4"
          align="center"
          width="full"
          justify="space-between"
          display={{ base: "flex", md: "none" }}
        >
          {!isSuggested && (
            <Link fontSize="sm" textDecor="underline" onClick={() => onClickDelete && onClickDelete(databaseId)}>
              Delete
            </Link>
          )}

          <Text fontWeight="bold"> {formatPrice(price)}</Text>

          {isSuggested && (
            <Box w={{ base: "50%", md: "50%" }}>
              {loggedIn ? (
                <AddToCart productId={databaseId} name="" price={price} />
              ) : (
                <Link href="/sign-in" w="100%">
                  <Button
                    border="1px solid #120B0C"
                    fontWeight={600}
                    variant="outlineSm"
                  >
                    SIGN IN TO ORDER
                  </Button>
                </Link>
              )}
            </Box>
          )}

          {!isSuggested && (
            <NumberInput
              min={1}
              value={qtyValue}
              onChange={(valueString, valueNumber) => {
                if (!updateLoading) {
                  setQtyValue(valueNumber);
                  onChangeQuantity(valueNumber);
                }
              }}
              focusBorderColor="#d28c49"
              width="20%"
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          )}
        </Flex>
      </Flex>
      {bundles && (
        <VStack w="100%" alignItems="flex-start" p="0 16px 24px 16px">
          <HStack alignItems="center">
            <GoStack width="20px !important" height="20px" strokeWidth="1px" />
            <Text color="gray.800" fontSize="16px" fontWeight="600">
              Pair {productName} with
            </Text>
          </HStack>
          <VStack w="100%">
            {bundles?.length === 1 ? (
              <OneProductBundle
                crossSellProducts={bundles}
                products={products}
                mainProductId={databaseId}
                mainProductPrice={price}
                isCart
              />
            ) : (
              <ProductBundles
                crossSellProducts={bundles}
                products={products}
                mainProductId={databaseId}
                mainProductPrice={price}
                isCart
              />
            )}
          </VStack>
        </VStack>
      )}
    </>
  );
};
