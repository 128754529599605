exports.components = {
  "component---src-components-product-single-page-tsx": () => import("./../../../src/components/product-single-page.tsx" /* webpackChunkName: "component---src-components-product-single-page-tsx" */),
  "component---src-components-product-single-variable-page-tsx": () => import("./../../../src/components/product-single-variable-page.tsx" /* webpackChunkName: "component---src-components-product-single-variable-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-account-settings-tsx": () => import("./../../../src/pages/account-settings.tsx" /* webpackChunkName: "component---src-pages-account-settings-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-customer-agreement-tsx": () => import("./../../../src/pages/customer-agreement.tsx" /* webpackChunkName: "component---src-pages-customer-agreement-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-how-to-order-tsx": () => import("./../../../src/pages/how-to-order.tsx" /* webpackChunkName: "component---src-pages-how-to-order-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-loyalty-program-tsx": () => import("./../../../src/pages/loyalty-program.tsx" /* webpackChunkName: "component---src-pages-loyalty-program-tsx" */),
  "component---src-pages-order-history-tsx": () => import("./../../../src/pages/order-history.tsx" /* webpackChunkName: "component---src-pages-order-history-tsx" */),
  "component---src-pages-outlet-tsx": () => import("./../../../src/pages/outlet.tsx" /* webpackChunkName: "component---src-pages-outlet-tsx" */),
  "component---src-pages-password-reset-tsx": () => import("./../../../src/pages/password-reset.tsx" /* webpackChunkName: "component---src-pages-password-reset-tsx" */),
  "component---src-pages-price-match-program-tsx": () => import("./../../../src/pages/price-match-program.tsx" /* webpackChunkName: "component---src-pages-price-match-program-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-promotions-tsx": () => import("./../../../src/pages/promotions.tsx" /* webpackChunkName: "component---src-pages-promotions-tsx" */),
  "component---src-pages-referral-program-tsx": () => import("./../../../src/pages/referral-program.tsx" /* webpackChunkName: "component---src-pages-referral-program-tsx" */),
  "component---src-pages-registration-tsx": () => import("./../../../src/pages/registration.tsx" /* webpackChunkName: "component---src-pages-registration-tsx" */),
  "component---src-pages-restricted-tsx": () => import("./../../../src/pages/restricted.tsx" /* webpackChunkName: "component---src-pages-restricted-tsx" */),
  "component---src-pages-shipping-return-policy-tsx": () => import("./../../../src/pages/shipping-return-policy.tsx" /* webpackChunkName: "component---src-pages-shipping-return-policy-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-pages-sign-in-tsx": () => import("./../../../src/pages/sign-in.tsx" /* webpackChunkName: "component---src-pages-sign-in-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */),
  "component---src-pages-ty-for-registration-tsx": () => import("./../../../src/pages/ty-for-registration.tsx" /* webpackChunkName: "component---src-pages-ty-for-registration-tsx" */),
  "component---src-pages-use-policy-tsx": () => import("./../../../src/pages/use-policy.tsx" /* webpackChunkName: "component---src-pages-use-policy-tsx" */),
  "component---src-templates-blog-author-tsx": () => import("./../../../src/templates/blog-author.tsx" /* webpackChunkName: "component---src-templates-blog-author-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-category-page-tsx": () => import("./../../../src/templates/categoryPage.tsx" /* webpackChunkName: "component---src-templates-category-page-tsx" */),
  "component---src-templates-products-by-brand-tsx": () => import("./../../../src/templates/productsByBrand.tsx" /* webpackChunkName: "component---src-templates-products-by-brand-tsx" */),
  "component---src-templates-products-by-category-tsx": () => import("./../../../src/templates/productsByCategory.tsx" /* webpackChunkName: "component---src-templates-products-by-category-tsx" */)
}

